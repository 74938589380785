import React from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Home = () => {
  return (
    <>
      <Header />
      <main>
        <Hero />
        <About />
        <Footer />
      </main>
    </>
  );
};

export default Home;
