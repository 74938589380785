import React from "react";
import shape1 from "../img/features/bg-shape-1.png";
import shape2 from "../img/features/bg-shape-2.png";
import ishape1 from "../img/features/shape-1.png";
import ishape3 from "../img/features/shape-3.png";

import icon1 from "../img/screen/websafe-ext-ui-2.png";
import mobile1 from "../img/screen/websafe-365-main-app-ui.png";

const Features = () => {
  return (
    <>
      <div
        id="apork-features-area"
        className="apork-features-area position-relative"
      >
        <div className="features-bg-shape">
          <img src={shape1} alt="featuresimg" className="style-shape fbs-1" />
          <img src={shape2} alt="featuresimg" className="style-shape fbs-2" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 mx-auto">
              <div className="section-title text-center mb-40">
                <h2>How It works</h2>
                <h5>
                  Kids web browser will require your permission before accessing any website.
                </h5>
              </div>
            </div>
          </div>

          <div className="row text-center">
            <div className="col-xl-6 col-lg-6 col-md-6 wow fadeInLeft">
              <div className="single-features">
                <h2 className="text-highlighter">On Kids Computer</h2>

                <img src={icon1} alt="featuresimg" className="kids-computer-blocked"/>
                <div className="features-shape">
                  <img src={ishape1} alt="featuresimg" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 wow fadeInRight">
              <div className="single-features">
                <h2 className="text-highlighter">On Parents Mobile App</h2>
                <img src={mobile1} alt="featuresimg" className="image-size"/>
                <div className="features-shape">
                  <img src={ishape3} alt="featuresimg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
