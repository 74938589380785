import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Pricing from "./pages/Pricing";
import Error from "./pages/Error";
import Features from "./pages/Features";
import Bloglist from "./pages/Bloglist";
import Bloggrid from "./pages/Bloggrid";
import Blogdetails from "./pages/Blogdetails";
import Contact from "./pages/Contact";
import Checkout from "./pages/Checkout";
import CheckoutRedirect from "./pages/CheckoutRedirect";
import TermsOfSales from "./pages/TermsOfSales";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import HowToSetup from "./pages/HowToSetup";
import React from "react";
import ClearCache from 'react-clear-cache';
import PermissionRequest from "./pages/PermissionRequest";
import GoogleAuthTokenPersistHandler from "./pages/GoogleAuthTokenPersistHandler";

const App: React.FC<{}> = () => {
  return (
      <Router>
      <div>
        <ClearCache>
          {({ isLatestVersion, emptyCacheStorage }) => (

                <Switch>
                  <Route
                      exact path={`${process.env.PUBLIC_URL + "/about"}`} component={About}
                  />
                  <Route
                      exact path={`${process.env.PUBLIC_URL + "/pricing"}`} component={Pricing}
                  />
                  <Route
                      exact path={`${process.env.PUBLIC_URL + "/error"}`} component={Error}
                  />
                  <Route
                      exact path={`${process.env.PUBLIC_URL + "/features"}`} component={Features}
                  />
                  <Route
                      exact path={`${process.env.PUBLIC_URL + "/blog-list"}`} component={Bloglist}
                  />
                  <Route
                      exact path={`${process.env.PUBLIC_URL + "/blog-grid"}`} component={Bloggrid}
                  />
                  <Route
                      exact path={`${process.env.PUBLIC_URL + "/blog-details"}`} component={Blogdetails}
                  />
                  <Route
                      exact path={`${process.env.PUBLIC_URL + "/contact"}`} component={Contact}
                  />
                  <Route
                      exact path={`${process.env.PUBLIC_URL + "/checkout/:checkoutData"}`} component={Checkout}
                  />
                  <Route
                      exact path={`${process.env.PUBLIC_URL + "/cr/:redirectData"}`} component={CheckoutRedirect}
                  />
                  <Route
                      exact path={`${process.env.PUBLIC_URL + "/auth/permission-request"}`} component={PermissionRequest}
                  />
                  <Route
                      exact path={`${process.env.PUBLIC_URL + "/auth/oauth2callback"}`} component={GoogleAuthTokenPersistHandler}
                  />
                  <Route
                      exact path={`${process.env.PUBLIC_URL + "/terms-and-conditions"}`} component={TermsOfSales}
                  />
                  <Route
                      exact path={`${process.env.PUBLIC_URL + "/setup"}`} component={HowToSetup}
                  />
                  <Route
                      exact path={`${process.env.PUBLIC_URL + "/privacy-policy"}`} component={PrivacyPolicy}
                  />
                  <Route
                      exact path={`${process.env.PUBLIC_URL + "/cookies-and-personal-data"}`} component={CookiePolicy}
                  />
                  <Route
                      exact path={`${process.env.PUBLIC_URL + "/"}`} component={Home}
                  />
                </Switch>
          )}
        </ClearCache>
      </div>
      </Router>
  );
};

export default App;
