import React, {Component} from "react";
import shape2 from "../img/features/bg-shape-2.png";
import websafeLogo from "../img/websafe-app-logo-900px.png";
import okIcon from "../img/websafe365/icons8-ok-64.png";
import axios from "axios";
import {withRouter} from "react-router";

class Checkout extends Component {
    constructor(props) {
        super(props);
        let checkoutData = '';
        try {
            checkoutData = JSON.parse(Buffer.from(props.match.params.checkoutData, "base64").toString());
            if(checkoutData.time < Date.now()){
                    window.location.href = "https://websafe365.com?urlExpired=true";
            }
        } catch (e) {
            checkoutData = {error: "this link has expired. Please try again via mobile app or chrome extension."};
        }
        this.state = {email: '', error: '', ...checkoutData};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({...this.state, email: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        const account = this.state.accountId;
        axios.post(`/api/account/${account}/create-checkout-session`, {
            email: this.state.email,
            time: this.state.time
        })
            .then((response) => {
                if (response.status === 200) {
                    const data = {
                        link: response.data.stripeLink,
                        t: (Date.now() + (1000 * 60 * 15))
                    };
                    const redirectData = Buffer.from(JSON.stringify(data)).toString("base64");
                    window.location.href = `/cr/${redirectData}`;
                } else {
                    if (response.data && response.data.checkoutError) {
                        console.log("error2z: " + response.data);
                        console.log("error2x: " + JSON.stringify(response.data));
                        this.setState({
                            ...this.state,
                            error: response.data.checkoutError
                        });
                    } else {
                        this.setState({
                            ...this.state,
                            error: "unexpected error occurred"
                        });
                        console.log("error2yyyy: " + response.data);
                        console.log("error2xyyy: " + JSON.stringify(response.data));
                    }

                }
            }, (error) => {
                if (error.response.status === 404) {
                    this.setState({
                        ...this.state,
                        error: "account not found for the given email address"
                    });
                } else if (error.response.status === 400) {
                    this.setState({
                        ...this.state,
                        error: error.response.data.checkoutError
                    });
                } else {
                    this.setState({
                        ...this.state,
                        error: "unexpected error occurred"
                    });
                }
            });
    }

    render() {
        return (
            <div
                id="checkout-body"
                className="checkout-body">
                <div className="features-bg-shape">
                    <img src={shape2} alt="featuresimg" className="style-shape fbs-2"/>
                </div>
                <div className="container padding-top-x1">
                    <div className="row text-center">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="checkout single-features">
                                <h3 className="text-left text-highlighter">WebSafe 365 Premium features:</h3>
                                <table className="text-left">
                                    <tbody>
                                    <tr>
                                        <td><img src={okIcon} alt="websafe-logo" className="img-fluid websafe-logo"/>
                                        </td>
                                        <td>Unlimited websites management.</td>
                                    </tr>

                                    <tr>
                                        <td><img src={okIcon} alt="websafe-logo" className="img-fluid websafe-logo"/>
                                        </td>
                                        <td>Set any time limit.</td>
                                    </tr>

                                    <tr>
                                        <td><img src={okIcon} alt="websafe-logo" className="img-fluid websafe-logo"/>
                                        </td>
                                        <td>Unlimited websites management.</td>
                                    </tr>
                                    <tr>
                                        <td><img src={okIcon} alt="websafe-logo" className="img-fluid websafe-logo"/>
                                        </td>
                                        <td>Manage rejected or accepted site list.</td>
                                    </tr>
                                    <tr>
                                        <td><img src={okIcon} alt="websafe-logo" className="img-fluid websafe-logo"/>
                                        </td>
                                        <td>1 year unlimited access.</td>
                                    </tr>
                                    </tbody>

                                </table>

                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 wow fadeInRight bg-black">
                            <form onSubmit={this.handleSubmit}>
                                <div className="checkout single-features">
                                    <img src={websafeLogo} alt="websafe-logo" className="img-fluid websafe-logo"/>
                                    <div className="padding-bottom-x1">
                                        <a href="/#"> Upgrade today</a>
                                        <a href="/#">
                                            <i>
                                                <del className="amount"> {this.state.oldPrice}</del>
                                            </i>,</a>
                                        <a href="/#"> limited time discount {this.state.price} </a>
                                    </div>
                                    <div className="col-xl-12">
                                        <p className="text-left">Account Email:</p>
                                        <input
                                            className="form-control"
                                            type="email"
                                            name="email"
                                            placeholder="Email" value={this.state.email} onChange={this.handleChange}
                                        />
                                        <p id="error-msg">{this.state.error}</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-12">

                                            <button type="submit" className="btn btn-type-2 checkout"
                                                    time={this.props.match.params.time}
                                                    data-account={this.props.match.params.accountId}
                                            >
                                                Continue to Checkout
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    };


}

export default withRouter(Checkout);
