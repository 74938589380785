import React from "react";
import axios from "axios";

const GoogleAuthTokenPersistHandler = () => {
    const useQuery = () => new URLSearchParams(window.location.search);
    const query = useQuery();
    const code = query.get('code');
    axios.post(`/api/auth/save-refresh-token`, {
        code: code
    })
        .then((response) => {
            if (response.status === 200) {
                console.log("window.location.href on save-refresh-token: ", window.location.href);
                window.location.href = "https://www.apple.com/mac/";
            }else {
                console.log("save-refresh-token: ", response.status);
                console.log("xxx: ", response);
            }
        }, (error) => {
            console.error("error: ", error);
        });

    return (
        <>
        </>
    );
};

export default GoogleAuthTokenPersistHandler;
