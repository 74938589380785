import React, {Component} from "react";

class CookiePolicy extends Component {

    render() {
        return (
            <div className="terms">
                <div className="container">
                    <ol className="breadcrumb">
                        <li><a href="/">Home > </a></li>
                        <li className="active">Cookie Policy</li>
                    </ol>

                    <section className="page-title center">
                        <h1 className="uppercase">Website Cookie Policy</h1>
                    </section>
                    <section className="block">
                        <div className="container">
                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>1. What is a cookie?</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p>A cookie is a small file of letters and numbers that we store on your browser or
                                        the hard drive of your computer or mobile device when you first visit one of our
                                        web pages.</p>
                                    <p>We use cookies to distinguish you from other users of our website. This helps us
                                        to provide you with a good experience when you browse our website and also
                                        allows us to improve the site. The cookies that we use enable us to recognise
                                        your device so you don’t have to give the same information several times during
                                        one task. They also recognise when you have given your username and password so
                                        that you don’t need to do it for every web page that you visit. They also
                                        measure how many people use our websites so that we can make it easier to use
                                        and ensure there’s enough capacity to make the site fast.</p>
                                    <p>Most cookies we use on our website will collect more general information such as
                                        how users arrive at and use our websites, or a user’s general location.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>2. What cookies do we use?</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p>Generally our cookies perform up to four different functions:</p>
                                    <p><b>Strictly necessary cookies: </b> These are cookies that are required for the
                                        operation of our website. They include, for example, cookies that enable you to
                                        log into secure areas of our website.</p>
                                    <p><b>Analytical/performance cookies: </b> These are cookies that allow us to
                                        recognise and count the number of visitors to this website and see how visitors
                                        move around the website when they are using it. This helps us to improve the way
                                        our website works, for example, by ensuring that users are finding what they are
                                        looking for easily.</p>
                                    <p><b>Functional cookies: </b> These cookies are used to recognise your device when
                                        you return to our website. This enables us to personalise our content for you,
                                        greet you by name and remember your preferences (for example, your choice of
                                        language or region).</p>
                                    <p><b>Tracking cookies: </b> These cookies record your visit to our website, the
                                        pages you have visited and the links you have followed. We will use this
                                        information to make our website and the advertising displayed on it more
                                        relevant to your interests. We may also share this information with third
                                        parties for this purpose.</p>

                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>3. Does anyone else use cookies on our website?</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p>Generally our cookies perform up to four different functions:</p>
                                    <p>Please note that third parties (including, for example, advertising networks and
                                        providers of external services like web traffic analysis services) may also use
                                        cookies on our website. For example, advertisers may use a profile they have
                                        built on sites that you have previously visited to present you with more
                                        relevant advertisements during your visit to our website.</p>
                                    <p>We have no control over third party cookies.</p>
                                    <p>These cookies are likely to be analytical/performance cookies or tracking
                                        cookies.</p>
                                    <p>Third party cookies; Our payment service provider(Stripe Inc, Stripe.com) may use
                                        cookies to identify your access to our website or to their website. Other 3rd
                                        party cookies can only be used for statistical analysis, for example to provide
                                        you with adverts that are more relevant to your interests.</p>
                                    <p>If you are based in the European Union and would like to learn more about how
                                        advertisers use these types of cookies or to choose not to receive them, please
                                        visit www.youronlinechoices.eu If you are based in the United States and would
                                        like to learn more, please visit http://www.aboutads.info/choices/.</p>
                                    <p>If you would like to disable third party cookies you can do so by going to the
                                        relevant third party website and opting out there.</p>
                                    <p>Please note that if you opt out of third party advertising cookies, you will
                                        still continue to receive advertising when using the website, however that
                                        advertising will not be tailored to you.</p>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">
                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>4. How to control and delete cookies?</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p> PLEASE NOTE THAT IF YOU USE YOUR BROWSER SETTINGS TO BLOCK ALL COOKIES
                                        (INCLUDING ESSENTIAL COOKIES) YOU MAY NOT BE ABLE TO ACCESS ALL OR PARTS OF OUR
                                        SITE.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>5. Contacting us</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b> You can contact us via info@websafe365.com to discuss any concerns you
                                        have on our cookie policy.</b></p>
                                </div>

                            </div>
                        </div>
                    </section>

                </div>
            </div>
        );
    };


}

export default CookiePolicy;
