import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Checkout2 from "../components/CheckoutRedirect";

const CheckoutRedirect = () => {
  return (
    <>
      <Header />
      <main>
        <Checkout2/>
        <Footer />
      </main>
      <Scrolltop />
    </>
  );
};

export default CheckoutRedirect;
