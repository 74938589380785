import React from "react";

const About = () => {
  return (
    <>
      <div id="apork-about-area" className="apork-about-area mt-230">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 mx-sm-0">
              <div className="section-title">
                <h1 className="text-highlighter">Explore our AI image edit showcase on Instagram</h1>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 mx-auto">
              <div className="section-title text-center">
                <iframe src="https://www.instagram.com/snap_refine/embed/" width="1000" height="1000" frameBorder="0"
                        scrolling="yes"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
