import React from "react";
import pricbg from "../img/prictable/prictable-bg.png";
import {Link} from "react-router-dom";

const Pricing = () => {
  return (
    <>
      <div
        id="apork-pricing-table-area"
        className="apork-pricing-table-area mt-80"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 mx-auto">
              <div className="section-title text-center mb-40">
                <h2>Pricing</h2>
                <p>7 Day trial, then £9.99/per year</p>
                <p>No auto renewals!</p>
              </div>
            </div>
          </div>
          <div className="price-tab">


            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="yearly"
                role="tabpanel"
                aria-labelledby="yearly-tab"
              >
                <div className="row">
                  <div className="col-xl-6 col-lg-6 wow fadeInLeft">
                    <div className="single-price-area text-left mb-30">
                      <div
                        className="single-price-bg"
                        style={{ background: `url('${pricbg}')` }}
                      ></div>
                      <div className="price-header">
                        <h4>7 Day trial</h4>
                        <h2>
                          £0.00<span className="sep"></span>

                        </h2>
                      </div>
                      <div className="price-item-list">
                        <ul>
                          <li>Full Feature access during trial period</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 wow fadeInUp">
                    <div className="single-price-area active text-left mb-30">
                      <div
                        className="single-price-bg"
                        style={{ background: `url('${pricbg}')` }}
                      ></div>
                      <div className="price-header">
                        <h4>Premium</h4>
                        <h2>
                          £9.99<span className="sep">/</span>
                          <span>yr</span>
                        </h2>
                      </div>
                      <div className="price-item-list">
                        <ul>
                          <li>Full feature access for 1 year</li>
                        </ul>
                      </div>
                      <div className="price-btn active mt-35">
                        <Link className="btn btn-type-5" to="#">
                          <i className="fa fa-check"></i> in App purchase
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
