import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import CookiePolicy1 from "../components/CookiePolicy";

const CookiePolicy = () => {
    return (
        <>
            <Header />
            <main>
                <CookiePolicy1/>
                <Footer />
            </main>
            <Scrolltop />
        </>
    );
};

export default CookiePolicy;
