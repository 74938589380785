import React from "react";
import axios from "axios";

const PermissionRequest =  () => {
     axios.get(`/api/auth/get-oauth-consent`)
        .then((response) => {
            if (response.status === 200) {
                window.location.href = response.data.url;
            }
        }, (error) => {
            console.error("error: ", error);
        });

    return (
        <>
        </>
    );
};

export default PermissionRequest;
