import React, {Component} from "react";
import {Link} from "react-router-dom";

class TermsOfSales extends Component {

    render() {
        return (
            <div className="terms">
                <div className="container">
                    <ol className="breadcrumb">
                        <li><a href="/">Home > </a></li>
                        <li className="active">Terms and Conditions</li>
                    </ol>
                    <section className="page-title center">
                        <h1 className="uppercase">Terms and Conditions</h1>
                    </section>

                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>1. SnapRefine with AI Introduction</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p>
                                        Welcome to SnapRefine.com, a website and mobile application operated by Innovotech Solutions LTD, a company
                                        registered in England & Wales (company number: 10641546) .
                                    </p>

                                    <p><b>'SnapRefine with AI' is a trading name for Innovotech Solutions Ltd, a company
                                        registered in England & Wales (company number: 10641546) . Our registered
                                        office address is at 128 City Road, London, England, EC1V
                                        2NX, United Kingdom</b>.</p>
                                    <p>"SnapRefine", "SnapRefine with AI", "The Company", “Ourselves”, “We”, “Our” and "Us", refers to
                                        our company, <b>Innovotech Solutions Ltd.</b></p>
                                    <p>
                                    By using our Service, you agree to be bound by these terms and conditions. If you do not agree to these terms and conditions, you may not use our Service.
                                    </p>


                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>2. Our Service</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p>
                                        The SnapRefine app is a tool that allows users to make changes to their photos using artificial intelligence technology. The app utilizes the OpenAI Dall-E engine to understand natural language input from users and make changes to the images based on that input.
                                    </p>
                                    <p>
                                        With the SnapRefine app, users can upload an image and use the built-in eraser tool to highlight specific areas. They can then provide a text description of the desired changes and, upon clicking "Refine image with AI," will be presented with a selection of modified images that match their description. Users can download their chosen image or make further adjustments as desired.
                                    </p>
                                    <p>
                                        Users can purchase "AI Credit bundles" of 50, 100, or 500 through in-app purchases on the Android and Apple App Stores. These bundles will be used each time the user chooses the "Refine Image with AI" option within the app, with the number of AI images generated being deducted from their purchased bundle.
                                    </p>
                                    <p>
                                        Please note that the SnapRefine app relies on the functionality of the OpenAI Dall-E engine to provide its services. If the OpenAI service is down, the SnapRefine app will not be able to function and make changes to images. Innovotech Solutions LTD shall not be held liable for any inability to use the SnapRefine app due to the unavailability of the OpenAI service.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>7. Your Personal Data and Privacy</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <ul className="bulleted">
                                        <li> We collect website access request data
                                            when a children clicks "request access" button to request permissions from
                                            you.
                                        </li>
                                        <li> We do <u>NOT</u> collect any personally identifiable data of the children
                                            on kids desktop computer (such as their name, email, age, phone number).
                                        </li>
                                    </ul>
                                    <p><b>Please read our
                                        <Link to="/privacy-policy"> Privacy Policy </Link> in full for further details.</b></p>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>8. Limitations</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <ul className="bulleted">
                                        <li>In no event shall we be liable for any damages whatsoever, including but not limited to any direct, indirect, special, incidental, or consequential damages, arising out of or in connection with the use or inability to use the Service.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">

                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>9. Contacting us</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p><b> You can contact us via info@websafe365.com</b></p>
                                </div>

                            </div>
                        </div>
                    </section>

                </div>
            </div>
        );
    };


}

export default TermsOfSales;
