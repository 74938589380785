import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import PrivacyPolicy1 from "../components/PrivacyPolicy";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <main>
        <PrivacyPolicy1/>
        <Footer />
      </main>
      <Scrolltop />
    </>
  );
};

export default PrivacyPolicy;
