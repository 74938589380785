import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import "font-awesome/css/font-awesome.min.css";
import ReactGA from 'react-ga4';
import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactGA.initialize("G-F7HP6MY634");
const history = createBrowserHistory();

let app = document.getElementById('root');
if (app) {
    // 1. Set up the browser history with the updated location
    // (minus the # sign)
    const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
    if (path) {
        history.replace(path);
    }

    // 2. Render our app
    ReactDOM.render(<App />, app);
}

reportWebVitals();
