import React, {Component} from "react";

class PrivacyPolicy extends Component {

    render() {
        return (
            <div className="terms">
                <div className="container">
                    <ol className="breadcrumb">
                        <li><a href="/">Home ></a></li>
                        <li className="active">Privacy Policy</li>
                    </ol>
                    <section className="block">
                        <div className="container">
                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>1. SnapRefine with AI - Privacy Notice</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p>This Privacy Policy explains how we handle and protect your personal data and your privacy rights under the law. Our trading name is SnapRefine and we are a part of Innovotech Solutions Ltd, a company registered in England & Wales (company number: 10641546) with our registered office at 128 City Road, London, England, EC1V 2NX. As the data controller, we are responsible for your personal data and will refer to ourselves as "SnapRefine", "we", "us", or "our" in this Privacy Policy. If you have any questions or requests to exercise your rights, please contact us at info@websafe365.com.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">
                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>2. Data Collection</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p>We collect images or photos, along with any text descriptions that you may add, that you upload through our mobile app for image processing and manipulation. The images and text descriptions are sent to the DALL-E 2 AI engine provided by OpenAI for processing. We only use this data to provide the services you requested and improve our services.</p>
                                    <p>NOTICE: We do not intentionally collect personal data relating to children.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="block">
                        <div className="container">
                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>3. Data Sharing</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p>We may share your data with third-party service providers, such as OpenAI, who
                                        provide the DALL-E 2 API. These third parties are bound by contractual
                                        obligations to keep your data confidential, secure, and to use it only for
                                        providing you with the services you requested. Your data will be stored by
                                        OpenAI and may be transferred to and stored outside the European Economic Area
                                        (EEA). By submitting your personal data, you agree to this transfer, storage,
                                        and processing. We will take all necessary steps to ensure that your data is
                                        treated securely and in accordance with this Privacy Policy.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="block">
                        <div className="container">
                            <div className="section-title">
                                <div className="description custom-header">
                                    <h2>4. Processing by Third Parties</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <p>We may share some or all of your personal data with third-party service providers
                                        for the purpose of providing our services, such as uploading and processing
                                        images through the DALL-E 2 AI API, and storing your data in cloud servers for
                                        backup and disaster recovery purposes. These third-party service providers are
                                        required to respect the security of your data and process it only in accordance
                                        with our instructions and the law.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="block">
                        <div class="container">
                            <div class="section-title">
                                <div class="description custom-header">
                                    <h2>5. Disclosing Your Personal Data</h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    <p>In the event of a sale or transfer of our business or assets, we may disclose your personal data to the prospective buyer. We may also disclose your personal data to any member of our group of companies or to comply with any legal obligation, protect our rights, or enforce our agreements. The data we collect from you may be transferred and stored outside the EEA and processed by staff outside the EEA. By submitting your personal data, you agree to this transfer, storage, and processing.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="block">
                        <div class="container">
                            <div class="section-title">
                                <div class="description custom-header">
                                    <h2>6. Contact Us</h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-sm-12">
                                    <p>If you have any questions, concerns about this Privacy Policy or if you would like to delete your account, please contact us at info@websafe365.com.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div></div>
        );
    };


}

export default PrivacyPolicy;
